<template>

<app-content :loading="is.loading">

	<app-content-head :title="(isNew) ? 'Create' : item.game.name" back="Convention.Checkout.Reports" :readonly="$authorised('con/checkout/access', 'read')">

		<app-content-head-actions-item :disabled="!isDirty" icon="undo" text="Undo" v-on:click="onUndoClick" />
		<app-content-head-actions-item :loading="is.saving" :disabled="!isDirty || !isValid" icon="save" text="Save" v-on:click="onSaveClick" />
		<app-content-head-actions-item v-if="!isNew" :disabled="isNew || item.winner" :danger="true" icon="delete" text="Delete" v-on:click="onDeleteClick" />

	</app-content-head>

	<app-content-body :loading="is.loading && is.initialised">

		<app-content-box :readonly="$authorised('con/checkout/access', 'read')">

			<app-input-suggest :disabled="!isNew" :locked="!isNew" label="Game" v-model="model.game" :validation="$v.model.game" api="convention/game" image="image" placeholder="Search game" />
			<app-input-toggle label="Status" v-model="model.status" :validation="$v.model.status" :options="$constants.checkout.report.statusName" />
			<app-input-text v-if="isNew" :autogrow="true" label="Report" v-model="model.report" :validation="$v.model.report" placeholder="Enter report details..." />

		</app-content-box>

		<app-widget-chat v-if="!isNew" :no-head="true" :id="item.id" url="convention/messages" />

	</app-content-body>

</app-content>

</template>

<script>

import mixForm from '@/mixin/form'
import { required } from 'vuelidate/lib/validators'

export default {

	mixins: [mixForm],

	data: function() {

		return {
			readonly: this.$authorised('con/checkout/access', 'read'),
			model: {
				id: false,
				game: false,
				status: 1,
				report: ''
			}
		}

	},

	validations: {
		model: {
			game: {
				required
			},
			status: {
				required
			},
			report: {
				
			}
		}
	}

}

</script>

<style scoped>

</style>